import React, { useState, Suspense, useEffect } from "react";
import { find } from "lodash";
import { ThemeProvider } from "@material-ui/core/styles";
import appTheme from "./config/MuiAppTheme";

import "./styles/inch-paper.css";

import {
  useParams,
  BrowserRouter as Router,
  Switch as RouterSwitch,
  Route,
  useHistory,
  useLocation
} from "react-router-dom";

import { report_pageview } from "./helpers/GA";

import AppToolbar from "./templates/toolbar/AppToolbar";
import axios from "axios";

// import 'normalize.css';
import bijMuiTheme from "./projects/bij/config/bijMuiTheme";
import wfeMuiTheme from "./projects/_modular/config/muiTheme";
import qcMuiTheme from "./templates/landing/config/landingMuiTheme";
import HTMLRenderer from "./templates/HTMLRenderer";
import { get_db_base, isIdBundle } from "./helpers/Functions";
import {
  get_ac_cookie,
  set_ac_cookie,
  validate_hash,
  request_ac_config,
  ACContext
} from "./helpers/access_code";
import { SelectedLocaleContext, UrlParamsContext } from "./templates/context";

import { HistoryOutlined } from "@material-ui/icons";
import { updateSearchParam } from "./helpers/ScannersBijFunctions";

const ProjectScanners = React.lazy(() =>
  import("./projects/scanners/ProjectScanners")
);
const ProjectTSeries = React.lazy(() =>
  import("./projects/t-series/ProjectTSeries")
);
const LandingPreview = React.lazy(() => import("./templates/landing"));
const TSeriesLandingPreview = React.lazy(() =>
  import("./projects/t-series/templates/landing")
);

const ProjectProjectorLenses = React.lazy(() =>
  import("./projects/projector-lenses/ProjectProjectorLenses")
);
const ProjectBIJ = React.lazy(() => import("./projects/bij"));

const GeneralizedProject = React.lazy(() => import("./projects/scanners-v2"));
const ScannersResub = React.lazy(() =>
  import("./projects/scanners/Resubscribe")
);

const ModularProject = React.lazy(() => import("./projects/_modular"));

const ModularProjectLandingPreview = React.lazy(() =>
  import("./projects/_modular/landing")
);

export function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function getAC(query) {
  for (const [key, value] of query) {
    if (key === value) return key;
  }
  return "";
}

function requestPagetitle(project) {
  const base = get_db_base(project);
  return axios
    .get(
      `${base}items/global_settings/1?fields=locale.page_title,locale.language`
    )
    .then((result) => {
      const locale =
        find(result?.data?.data?.locale || [], (l) => l.language === "en-US") ||
        {};
      return locale.page_title || "";
    })
    .catch((err) => "");
}

const ProjectValidator = React.memo((props) => {
  const [loaded, setLoaded] = useState(false);
  const [acConfig, setACConfig] = useState(undefined);
  const [authAttempts, setAuthAttempts] = useState(0);
  const [authenticated, setAuthenticated] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [pdfExportData, setToolbarData] = useState(null);
  const [error, setError] = useState(false);
  const [selectedContact, setContact] = useState(null);
  const [selectedAudience, setAudience] = useState(null);
  const [selectedLocale, setLocale] = useState(null);
  const [urlParams, setUrlParams] = useState({
    history: useHistory(),
    location: useLocation()
  });
  const [exposeContent, setExposeContent] = useState(null);
  const [pagesToExport, setPagesToExport] = useState([]);
  const [idOfSinglePageElementToExport, setIdOfSinglePageElementToExport] =
    useState(null);
  // const [open, setOpen] = React.useState(false);
  const [invalidAC, setInvalidAC] = useState(false);
  const [validatedAC, setValidatedAC] = useState(null);
  const [pageviewReported, setPageviewReported] = useState(false);

  // const [enteredAccessCode, setEnteredAccessCode] = useState('');
  // const [rememberCode, setRememberCode] = useState(true);
  const params = useParams();
  const history = useHistory();
  const location = useLocation();

  // const handleClose = () => {
  //   setOpen(false);
  //   validateCode(enteredAccessCode)
  // };

  const toolbarActions = {
    setToolbarData,
    selectedContact,
    selectedAudience,
    setAudience,
    selectedLocale,
    setLocale,
    setIdOfSinglePageElementToExport,
    setExposeContent,
    exposeContent,
    setPagesToExport,
    pagesToExport
  };

  const toolbarData = {
    idOfSinglePageElementToExport,
    project: params.project
  };

  const toolbarsActionsAndData = {
    ...toolbarActions,
    ...toolbarData
  };

  // Collect route
  const incrementAuthAttempts = () => setAuthAttempts(authAttempts + 1);
  const access_code_enabled = acConfig?.preview_access === "access-code";

  useEffect(() => {
    if (params.project) {
      // Verify if project requires access code
      request_ac_config(params.project)
        .then((result) => setACConfig(result))
        .catch((err) => setACConfig({}));

      // Set Page Title
      requestPagetitle(params.project).then((page_title) => {
        if (page_title) {
          document.title = page_title;
        }
      });
    } else {
      setACConfig({});
    }
  }, []);

  const nested_routes_to_validate = ["landing"];
  const bundleIdPresent = isIdBundle(params.possibleBundleId);
  const skipAC = !!params.skipAC && bundleIdPresent;
  const query = useQuery();
  // const query_ac = unescape(query.get('ac') || '')
  const query_ac = getAC(query);
  const preserveAC = !!query.get("rememberAC") || false;
  const validateCode = (entered_access_code) => {
    if (!!entered_access_code && entered_access_code.length > 0) {
      // Validate against access code
      return validate_hash(
        params.project,
        entered_access_code,
        acConfig.access_hash
      )
        .then((ac_valid) => {
          if (ac_valid) {
            if (/* rememberCode && */ preserveAC)
              set_ac_cookie(entered_access_code);

            setAuthenticated(true);
            return true;
          } else {
            return false;
          }
        })
        .finally(() => incrementAuthAttempts());
    }
  };

  useEffect(() => {
    const page = pagesToExport?.[0];
    //check if pagesToExport is in form [1] or {name: ProductName, id: 1, pages: [...pages]}
    const pageIsObject = page?.id ? true : false;
    //if list of objects, get the list of those IDs to add to search param
    //otherwise send null to the function to remove that search param
    const paramValue = pageIsObject ? pagesToExport.map((p) => p.id) : null;
    updateSearchParam(urlParams, setUrlParams, "comparisons", paramValue);
  }, [pagesToExport]);

  useEffect(() => {
    if (selectedLocale && params.project.includes("latin")) {
      updateSearchParam(urlParams, setUrlParams, "lang", selectedLocale);
    }
  }, [selectedLocale]);

  useEffect(() => {
    let comparisons = query.get("comparisons");
    if (comparisons) {
      comparisons = comparisons.split(",");
      setPagesToExport(comparisons.map((c) => parseInt(c)));
    }
    // set initial value for selectedLocale from query string
    const langquery = query.get("lang");
    if (langquery) {
      if (!selectedLocale) {
        setLocale(langquery);
      } else if (typeof selectedLocale === "string") {
        if (langquery.toLowerCase() !== selectedLocale.toLowerCase()) {
          setLocale(langquery);
        }
      } else if (
        langquery.toLowerCase() !== selectedLocale.langquery.toLowerCase()
      ) {
        setLocale(langquery);
      }
    }
  }, []);

  // Handle access code authentication
  useEffect(() => {
    if (
      access_code_enabled === true &&
      !authenticated &&
      !invalidAC &&
      !skipAC
    ) {
      const existing_cookie = get_ac_cookie();
      const validate_cookie = existing_cookie.length > 0;
      const validate_code = query_ac.length > 0;
      const code_to_validate = validate_cookie ? existing_cookie : query_ac;
      if (code_to_validate.length > 0) {
        validateCode(code_to_validate).then((validity) => {
          if (validity) {
            setInvalidAC(false);
            setValidatedAC(code_to_validate);
          } else if (!validity && validate_cookie && validate_code) {
            validateCode(query_ac).then((validity) => {
              setInvalidAC(!validity);
              if (validity) setValidatedAC(query_ac);
            });
          } else setInvalidAC(true);
        });
      } else {
        setInvalidAC(true);
        // setOpen(true);
      }
    }
  }, [acConfig, authenticated, invalidAC, authAttempts]);

  // Project not valid
  if (!params.project) {
    // report_pageview();
    return <React.Fragment />;
  }

  if (params.project === "scanners") {
    return (
      <Suspense fallback={<div />}>
        <ScannersResub />
      </Suspense>
    );
  }

  if (!submitted) {
    // Validate project
    axios
      .get(get_db_base(params.project))
      .then((result) => setLoaded(true))
      .catch((err) => setError(true));

    setSubmitted(true);
  }

  if (error) {
    // report_pageview();
    return <React.Fragment />;
  }

  if (acConfig === undefined) {
    return <React.Fragment />;
  }

  let show_spinner = !loaded || !pdfExportData;

  // if (pdfExportData)
  //   report_pageview();

  const globalSettings =
    pdfExportData?.additional?.dbDataRaw?.global_settings || {};

  const toolbarActionsAndData = {
    projectType: params.project,
    pdfExportData,

    setContact,
    selectedContact,
    setAudience,
    selectedAudience,
    setLocale,
    selectedLocale,
    setExposeContent,
    exposeContent,
    setPagesToExport,
    pagesToExport,

    globalSettings,

    setIdOfSinglePageElementToExport,
    idOfSinglePageElementToExport
  };

  const should_auth = access_code_enabled === true && !authenticated;
  const validated_url_ac =
    access_code_enabled === true && validatedAC
      ? `${validatedAC}=${validatedAC}`
      : "";
  // Project loaded
  return (
    <Router>
      {
        should_auth && invalidAC && <p>Access code required</p>
        /*
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
          disableBackdropClick={true}
          disableEscapeKeyDown={true}
        >
          <DialogTitle id="form-dialog-title">Please Enter Access Code</DialogTitle>
          <DialogContent>
            <DialogContentText></DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Access Code"
              fullWidth
              onChange={(e) => setEnteredAccessCode(e.target.value)}
            />
           <FormControlLabel
            checked={rememberCode}
            control={<Switch color="primary" />}
            label="Remember code"
            onChange={(e) => setRememberCode(e.target.checked)}
          />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Enter
            </Button>
          </DialogActions>
        </Dialog>
        */
      }
      {(!should_auth || skipAC) && (
        <ACContext.Provider value={{ url_ac: validated_url_ac }}>
          <UrlParamsContext.Provider value={{ urlParams, setUrlParams }}>
            <SelectedLocaleContext.Provider
              value={{ selectedLocale, setLocale }}
            >
              {!!show_spinner && (
                <img id="spinner" src="/spinner.gif" alt="Loading..." />
              )}
              <ThemeProvider theme={appTheme}>
                <AppToolbar {...toolbarActionsAndData} />
                <main
                  className={`${idOfSinglePageElementToExport ? "spe" : ""}`}
                >
                  <Suspense fallback={<div />}>
                    <RouterSwitch>
                      <Route
                        path={[
                          "/t-series/landing/:bundleId?/:id0?/:id1?/:id2?/:id3?",
                          "/p-series/landing/:bundleId?/:id0?/:id1?/:id2?/:id3?"
                        ]}
                      >
                        <ThemeProvider theme={qcMuiTheme}>
                          <TSeriesLandingPreview
                            project={params.project}
                            setToolbarData={setToolbarData}
                          />
                        </ThemeProvider>
                      </Route>
                      <Route
                        path={[
                          "/_modular/landing/:bundleId?/:unused?",
                          "/wf-enterprise/landing/:bundleId?/:unused?",
                          "/latin-wf-enterprise/landing/:bundleId?/:unused?",
                          "/latin-es-wf-enterprise/landing/:bundleId?/:unused?",
                          "/latin-pt-wf-enterprise/landing/:bundleId?/:unused?",
                          "/wf-enterprise-grow/landing/:bundleId?/:unused?",
                          "/wf-enterprise-am-a3/landing/:bundleId?/:unused?",
                          "/wf-enterprise-am-a4/landing/:bundleId?/:unused?",
                          "/latin-es-wf-enterprise-am/landing/:bundleId?/:unused?",
                          "/latin-pt-wf-enterprise-am/landing/:bundleId?/:unused?"
                        ]}
                      >
                        <ThemeProvider theme={wfeMuiTheme}>
                          <ModularProjectLandingPreview
                            {...toolbarsActionsAndData}
                          />
                        </ThemeProvider>
                      </Route>
                      <Route
                        path={`/:project/landing/:bundleId?/:id0?/:id1?/:id2?/:id3?`}
                      >
                        <ThemeProvider theme={qcMuiTheme}>
                          <LandingPreview
                            project={params.project}
                            setToolbarData={setToolbarData}
                            {...toolbarActionsAndData}
                          />
                        </ThemeProvider>
                      </Route>
                      <Route
                        path={`/:project/qcl-spe/:bundleId?/:id0?/:id1?/:id2?/:id3?`}
                      >
                        <GeneralizedProject
                          qclSpe={true}
                          {...toolbarsActionsAndData}
                        />
                      </Route>
                      {["/:project/:s3Key/:objectKey", "/:project/:s3Key"].map(
                        (path, path_idx) => (
                          <Route path={path} key={path_idx}>
                            <HTMLRenderer setToolbarData={setToolbarData} />
                          </Route>
                        )
                      )}
                      <Route path={["/scanners", "/latin-scanners"]}>
                        {/* <ProjectScanners
                        project="scanners"
                        {...toolbarsActionsAndData}
                      /> */}
                        <GeneralizedProject {...toolbarsActionsAndData} />
                      </Route>
                      <Route path={["/t-series", "/p-series"]}>
                        <ProjectTSeries {...toolbarsActionsAndData} />
                      </Route>
                      {["/:project/:s3Key/:objectKey", "/:project/:s3Key"].map(
                        (path, path_idx) => (
                          <Route path={path} key={path_idx}>
                            <HTMLRenderer setToolbarData={setToolbarData} />
                          </Route>
                        )
                      )}
                      <Route path={`/scanners`}>
                        {/*<ProjectScanners
                      project="scanners"
                      {...toolbarsActionsAndData}
                    />*/}
                        <GeneralizedProject {...toolbarsActionsAndData} />
                      </Route>
                      <Route path={`/projector-lenses`}>
                        <ProjectProjectorLenses {...toolbarsActionsAndData} />
                      </Route>
                      <Route path={["/bij", "/latin-bij"]}>
                        <ThemeProvider theme={bijMuiTheme}>
                          <ProjectBIJ {...toolbarsActionsAndData} />
                        </ThemeProvider>
                      </Route>
                      <Route
                        path={[
                          "/wf-enterprise",
                          "/latin-wf-enterprise",
                          "/latin-es-wf-enterprise",
                          "/latin-pt-wf-enterprise",
                          "/wf-enterprise-grow",
                          "/wf-enterprise-am-a3",
                          "/wf-enterprise-am-a4",
                          "/latin-es-wf-enterprise-am",
                          "/latin-pt-wf-enterprise-am",
                          "/colorworks"
                        ]}
                      >
                        <ModularProject {...toolbarsActionsAndData} />
                      </Route>
                      <Route path={["/scanners", "/latin-scanners"]}>
                        {/* <ProjectScanners
                      project="scanners"
                      {...toolbarsActionsAndData}
                    /> */}
                        <GeneralizedProject {...toolbarsActionsAndData} />
                      </Route>
                    </RouterSwitch>
                  </Suspense>
                </main>
              </ThemeProvider>
            </SelectedLocaleContext.Provider>
          </UrlParamsContext.Provider>
        </ACContext.Provider>
      )}
    </Router>
  );
});

export default ProjectValidator;
